import { getField, updateField } from 'vuex-map-fields'
import { prepareUrl, uri } from '~/api/uri'

export const initState = {
  turnover: [],
  turnoverTime: null,
  clientTotals: [],
  clientTotalsTime: null,
  twoDaysTotal: {},
  twoDaysChart: {},
  sevenDaysTotal: {},
  sevenDaysChart: {},
  thirtyDaysTotal: {},
  thirtyDaysChart: {},
  monthlyTotal1: {},
  monthlyTotal2: {},
  monthlyTotal3: {},
  monthlyTotal4: {},
  monthlyTotal5: {},
  monthlyTotal6: {},
  monthlyTotal7: {},
  monthlyTotal8: {},
  monthlyTotal9: {},
  monthlyTotal10: {},
  monthlyTotal11: {},
  monthlyTotal12: {},
  monthlyChart1: {},
  monthlyChart2: {},
  monthlyChart3: {},
  monthlyChart4: {},
  monthlyChart5: {},
  monthlyChart6: {},
  monthlyChart7: {},
  monthlyChart8: {},
  monthlyChart9: {},
  monthlyChart10: {},
  monthlyChart11: {},
  monthlyChart12: {}
}

export const state = () => {
  return {
    ...initState
  }
}

function formatCredits (value) {
  // format number to Euro
  const credits = Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 0
  })
  return credits.format(value || 0)
}
function formatFloat (value) {
  // format number to Euro
  const credits = Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2
  })
  return credits.format(value || 0)
}
function formatCurrency (value) {
  // format number to Euro
  const euro = Intl.NumberFormat('de-DE', {
    style: 'decimal',
    useGrouping: 'always',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })
  return `€${euro.format(Math.round(parseFloat(value || '0')))}`
}
function prepareTotalsData (value) {
  value.data.amount = formatCurrency(value?.data?.amount || 0)
  value.data.credits = formatCredits(value?.data?.credits || 0)
  return value
}
function prepareChartData (value) {
  if (value?.data?.credits?.data?.length) {
    value.data.credits.data = value.data.credits.data
      .map((i) => {
        i.l = formatCredits(i.y)
        return i
      })
  }
  if (value?.data?.revenue?.data?.length) {
    value.data.revenue.data = value.data.revenue.data
      .map((i) => {
        i.l = formatCurrency(i.y)
        return i
      })
  }
  return value
}
function prepareTurnoverData (value) {
  if (value?.length) {
    value = value
      .map((i) => {
        i.credits = formatCredits(i.credits)
        i.records_count = formatCredits(i.records_count)
        i.display_amount = formatCurrency(i.amount)
        return i
      })
  }
  return value
}
function prepareClientTotalsData (value) {
  if (value) {
    value.clients_total = formatCredits(value.clients_total)
    value.credits_total = formatCredits(value.credits_total)
    value.credits_avg = formatFloat(value.credits_avg)
  }
  return value
}

export const actions = {
  async loadTurnover ({ commit }) {
    const res = await this.$axios(uri.dashboard.turnover)
    if (res.data && res.data.data) {
      commit('setTurnover', res.data)
    }
  },
  async updateTurnover ({ commit }) {
    const res = await this.$axios.$post(uri.dashboard.turnover)
    if (res.data && res.data.data) {
      commit('setTurnover', res.data)
    }
  },
  async loadClientTotals ({ commit }) {
    const res = await this.$axios(uri.dashboard.clientTotals)
    if (res.data && res.data.data) {
      commit('setClientTotals', res.data)
    }
  },
  async sevenDaysTotal ({ commit }) {
    const res = await this.$axios(uri.dashboard.sevenDaysTotal)
    if (res.data) {
      commit('setSevenDaysTotal', res.data)
    }
  },
  async sevenDaysChart ({ commit }) {
    const res = await this.$axios(uri.dashboard.sevenDaysChart)
    if (res.data) {
      commit('setSevenDaysChart', res.data)
    }
  },
  async sevenDaysUpdate ({ commit }) {
    await this.$axios.$post(uri.dashboard.sevenDays)
  },
  async thirtyDaysTotal ({ commit }) {
    const res = await this.$axios(uri.dashboard.thirtyDaysTotal)
    if (res.data) {
      commit('setThirtyDaysTotal', res.data)
    }
  },
  async thirtyDaysChart ({ commit }) {
    const res = await this.$axios(uri.dashboard.thirtyDaysChart)
    if (res.data) {
      commit('setThirtyDaysChart', res.data)
    }
  },
  async thirtyDaysUpdate ({ commit }) {
    await this.$axios.$post(uri.dashboard.thirtyDays)
  },
  async monthlyTotal ({ commit }, { year, month }) {
    const res = await this.$axios(prepareUrl(uri.dashboard.monthlyTotal, { year, month }))
    if (res.data) {
      commit('setMonthlyTotal', { year, month, data: res.data })
    }
  },
  async monthlyChart ({ commit }, { year, month }) {
    const res = await this.$axios(prepareUrl(uri.dashboard.monthlyChart, { year, month }))
    if (res.data) {
      commit('setMonthlyChart', { year, month, data: res.data })
    }
  },
  async monthlyUpdate ({ commit }, { year, month }) {
    await this.$axios.$post(prepareUrl(uri.dashboard.monthly, { year, month }))
  }
}

export const mutations = {
  updateField,
  setTurnover (state, value) {
    state.turnover = prepareTurnoverData(value.data)
    state.turnoverTime = value.meta?.time
  },
  setClientTotals (state, value) {
    state.clientTotals = prepareClientTotalsData(value.data)
    state.clientTotalsTime = value.meta?.time
  },
  setTwoDaysTotal (state, value) {
    state.twoDaysTotal = prepareTotalsData(value)
  },
  setTwoDaysChart (state, value) {
    state.twoDaysChart = prepareChartData(value)
  },
  setSevenDaysTotal (state, value) {
    state.sevenDaysTotal = prepareTotalsData(value)
  },
  setSevenDaysChart (state, value) {
    state.sevenDaysChart = prepareChartData(value)
  },
  setThirtyDaysTotal (state, value) {
    state.thirtyDaysTotal = prepareTotalsData(value)
  },
  setThirtyDaysChart (state, value) {
    state.thirtyDaysChart = prepareChartData(value)
  },
  setMonthlyTotal (state, { year, month, data }) {
    state[`monthlyTotal${month}`] = prepareTotalsData(data)
  },
  setMonthlyChart (state, { year, month, data }) {
    state[`monthlyChart${month}`] = prepareChartData(data)
  }
}

export const getters = {
  getField,
  turnover (state) {
    return state.turnover
  },
  turnoverTime (state) {
    return state.turnoverTime
  },
  clientTotals (state) {
    return state.clientTotals
  },
  clientTotalsTime (state) {
    return state.clientTotalsTime
  },
  twoDaysTotal (state) {
    return state.twoDaysTotal
  },
  twoDaysChart (state) {
    return state.twoDaysChart
  },
  sevenDaysTotal (state) {
    return state.sevenDaysTotal
  },
  sevenDaysChart (state) {
    return state.sevenDaysChart
  },
  thirtyDaysTotal (state) {
    return state.thirtyDaysTotal
  },
  thirtyDaysChart (state) {
    return state.thirtyDaysChart
  },
  monthlyTotal1 (state) {
    return state.monthlyTotal1
  },
  monthlyTotal2 (state) {
    return state.monthlyTotal2
  },
  monthlyTotal3 (state) {
    return state.monthlyTotal3
  },
  monthlyTotal4 (state) {
    return state.monthlyTotal4
  },
  monthlyTotal5 (state) {
    return state.monthlyTotal5
  },
  monthlyTotal6 (state) {
    return state.monthlyTotal6
  },
  monthlyTotal7 (state) {
    return state.monthlyTotal7
  },
  monthlyTotal8 (state) {
    return state.monthlyTotal8
  },
  monthlyTotal9 (state) {
    return state.monthlyTotal9
  },
  monthlyTotal10 (state) {
    return state.monthlyTotal10
  },
  monthlyTotal11 (state) {
    return state.monthlyTotal11
  },
  monthlyTotal12 (state) {
    return state.monthlyTotal12
  },
  monthlyChart1 (state) {
    return state.monthlyChart1
  },
  monthlyChart2 (state) {
    return state.monthlyChart2
  },
  monthlyChart3 (state) {
    return state.monthlyChart3
  },
  monthlyChart4 (state) {
    return state.monthlyChart4
  },
  monthlyChart5 (state) {
    return state.monthlyChart5
  },
  monthlyChart6 (state) {
    return state.monthlyChart6
  },
  monthlyChart7 (state) {
    return state.monthlyChart7
  },
  monthlyChart8 (state) {
    return state.monthlyChart8
  },
  monthlyChart9 (state) {
    return state.monthlyChart9
  },
  monthlyChart10 (state) {
    return state.monthlyChart10
  },
  monthlyChart11 (state) {
    return state.monthlyChart11
  },
  monthlyChart12 (state) {
    return state.monthlyChart12
  }
}
